import React from "react";
import cx from "classnames";

import FullScreenButton from "../components/FullScreenButton";
import PageFooter from "./PageFooter";
import styles from "./Preview.module.css";

const PageContent = ({ files }) => (
  <>
    <div>Content: </div>
    {files.map((file) => (
      <div key={'unique'+file.ref} className={styles.file}>
        {file.title.replace(/ /g, "_")}
      </div>
    ))}
  </>
);

const ContentPage = ({ files, isFullScreen, onOpen, pageTotal }) => (
  <div
    className={cx(styles.Page, styles.ContentPage, {
      [styles.fullScreen]: isFullScreen,
    })}
  >
    {onOpen && <FullScreenButton onClick={onOpen} />}
    <PageContent files={files} />
    <PageFooter page={2} pageTotal={pageTotal} />
  </div>
);

export default ContentPage;

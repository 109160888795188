import React from "react";
import cx from "classnames";

import styles from "./FileStack.module.css";

function numberToWord (number) {
  if (number === 1) return "one";
  if (number === 2) return "two";
  if (number === 3) return "three";
  if (number === 4) return "four";

  return "five"
}

function FileStack(props) {
  const { files, limit } = props;

  return (
    <div className={styles.root}>
      <img src={files[0].url} alt=""/>
      <div className={cx(styles.stack, styles[numberToWord(files.length)])}>
        {files.slice(0, limit).map((file) => (
          <img key={file.id} src={file.url} alt="" />
        ))}
      </div>
    </div>
  );
}

FileStack.defaultProps = {
  limit: 5,
};

export default FileStack;

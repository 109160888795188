import React, { useEffect, useRef } from "react";

import styles from "./Carousel.module.css";

const Video = ({ src, isActive, isMuted }) => {
  const videoRef = useRef();

  useEffect(() => {
    const currentVideo = videoRef.current;

    if (isActive) {
      currentVideo.play();
    } else {
      currentVideo.pause();

      setTimeout(() => {
        currentVideo.currentTime = 0;
      }, 500)
    }

  }, [isActive]);

  return (
    <video
      className={styles.video}
      loop
      muted={isMuted}
      preload="true"
      ref={videoRef}
      src={src}
      playsInline
    />
  );
};
export default Video;

/*
Realtime database context for TAAT app

i.e. GLOBAL VARS ARE SET HERE

loggedIn
contributor
--
stores edits (0=current)


Database structure:

/edits
/pads

/live/edits

/live
  /pads
    /edits


*/


import React, { useEffect, useState } from "react";
import { auth, base, paths } from "./config";



export const AuthContext = React.createContext();


export const BaseContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);     //firebase auth user object

  const username = user ? String(user.displayName.split(" ").splice(-1)).toLowerCase() : 'anon'

  //const [edits, setEdits] = useState({});

  const [meta, setMeta] = useState({}) // pad metadata

  const [editsMetadata, setEditsMetadata] = useState([{name: 'test', key: 'test'}])
  const [activeEdit, setActiveEdit] = useState();
  const [activeEditId, setActiveEditId] = useState(null);


  useEffect(()=>{
    console.log('pad metadata: ', meta)
  }, [meta])

  useEffect(()=>{
    setActiveEdit(
      editsMetadata.find(edit=>edit.key===activeEditId)
    )
  }, [activeEditId, editsMetadata])

  useEffect(()=>{
    console.log('activeEdit', activeEdit)
  }, [activeEdit])


  useEffect(() => {
    base.ref(paths.EDIT_METADATA).on('value', snapshot => {
      let res = snapshot.val()

      res && setEditsMetadata(
        Object.entries(res).map(([key, edit]) => ({
          key: key,
          name: `${edit.lastEdited}_${edit.title}_${edit.editor}`,
          //name: `${key}_${edit.lastEdited}_${edit.title}_${edit.editor}`,
          ...edit
        }))
      )

    })

    const unsubscribe = auth.onAuthStateChanged(setUser);

    return () => {
      base.ref(paths.EDIT_METADATA).off()
      unsubscribe(); /* removes authlistener */
    }
  }, []);


  function onDeleteEdit() {
    if (!window.confirm('Are you sure you want to delete this edit? This cannot be undone.')) return
  
    base.ref(paths.EDIT_METADATA).child(activeEditId).remove()
    base.ref(paths.EDIT_STORAGE).child(activeEditId).remove()

    //setselectedfiles null
    //history push...
  }

  return (
    <AuthContext.Provider value={{
      user,
      username,
      editsMetadata,
      meta,
      setMeta,
      activeEdit,
      activeEditId,
      setActiveEditId,
      onDeleteEdit
    }}>
      {children}
    </AuthContext.Provider>
  );/* listOfEdits,  */
};

import React, { useState, useEffect, useCallback, useContext } from "react";
import cx from "classnames";
import { DISABLED_CLASS } from "../../constants";

import Image from "./Image";
import Pdf from "./LazyPdf";
import Video from "./LazyVideo";
import Pad from "./LazyPad";

import styles from "./File.module.css";
import {AuthContext} from "../../Firebase/context"

const File = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [title, setTitle] = useState('');
  const { activeEdit } = useContext(AuthContext)

  useEffect(()=>{
    setTitle(file.title || file[0].title);
  },[]);

  const wrapperSetTitle = useCallback(title => {
    setTitle(title);
  }, [setTitle]);

  const setSetTitle = (val) => setTitle(val);

  function toggleExpansion() {
    if (!isExpanded) {
      document.body.classList.add(DISABLED_CLASS);
      return setIsExpanded(true);
    }

    document.body.classList.remove(DISABLED_CLASS);
    return setIsExpanded(false);
  }
  const {
    file,
    onClick,
    onOpen,
    onScreencapture,
    isLarge,
    isSelected,
    scrollPosition,
    selectedFiles,
  } = props;

  const handleClick = (event) => {
    if (
      onClick &&
      !isExpanded &&
      event.target.nodeName !== "SPAN" &&
      event.target.nodeName !== "svg" &&
      event.target.nodeName !== "path" &&
      event.target.nodeName !== "BUTTON"
    ) {
      event.preventDefault();
      return onClick(file.id === undefined ? file[0] : file);
    }
  };

  const onPause = (time) => {
    onScreencapture(file, time);
  };

  return (
    <div
      className={cx(styles.File, {
        [styles.selected]: isSelected,
        [styles.selectedInEdit]: isSelected && activeEdit,
        [styles.clickable]: Boolean(onClick),
        [styles.large]: isLarge,
        [styles.expanded]: isExpanded,
        [styles.external]: !!file.externalURL,
      })}
      onClick={handleClick}
    >

      <div>
        {file.id === undefined ? (
          <Pdf
            files={file}
            onSelect={onClick}
            isExpanded={isExpanded}
            onToggleExpand={toggleExpansion}
            onOpen={onOpen}
            selectedFiles={selectedFiles}
          />
        ) : (
          <>
            {file.doctype === "clip" ? (
              <Video
                onOpen={() => onOpen(file.id)}
                url={file.url}
                onPause={onPause}
                poster={file.url}
                Key={file.Key}
                isFullscreen={isLarge}
                isSelected={isSelected}
              />
            ) : file.doctype === "pad" ? (
              <Pad
                onOpen={isLarge ? undefined : () => onOpen(file.id, file.externalURL)}
                file={file}
                isLarge={isLarge}
                titleSetter={setSetTitle}
              />
            ) : (
              <Image
                onOpen={isLarge ? undefined : () => onOpen(file.id, file.externalURL)}
                scrollPosition={scrollPosition}
                src={
                  file.poster_url ||
                  (isLarge ? file.url.replace("/thumbs/", "/large/") : file.url)
                }
                alt={file.title}
              />
            )}
          </>
        )}
        {!isLarge && (
          <div className={styles.title}>{title /*file.title || file[0].title*/}</div>
        )}
      </div>
    </div>
  );
};

export default File;

import React, { useState, useCallback } from "react";
import cx from "classnames";
import includes from "../../utils/includes";

import styles from "./Menu.module.css";
import { FACEBOOK, FILTER_TRANSLATIONS } from "../../constants";


const MenuBody = ({ header, items, selectedItems, onSelect, isOpen }) => {
  const [openGroup, setOpenGroup] = useState(undefined);

  const toggleGroup = useCallback((currentGroup, newGroup) => {
    if (currentGroup === newGroup) {
      return setOpenGroup();
    }

    return setOpenGroup(newGroup);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <ul className={styles.main}>
      <li><a href="/">home</a></li>
      <li><a href={FACEBOOK} target="_blank" rel="noopener noreferrer">agenda</a></li>
      <li className={styles.separator} />
      {Object.keys(items).map((group) => {
        const selectedGroupItems = selectedItems[group] || [];

        return (
          <React.Fragment key={group}>
            <li
              onClick={() => toggleGroup(openGroup, group)}
              className={cx({
                [styles.selected]: openGroup === group,
              })}
            >
              {FILTER_TRANSLATIONS[group]}
              {Boolean(selectedGroupItems.length) && (
                <span> {`(${selectedGroupItems.length})`}</span>
              )}
            </li>
            {openGroup === group && (
              <ul className={styles.nested}>
                {items[group].map((item) => (
                  <li
                    key={item}
                    className={cx({
                      [styles.selected]: includes(selectedGroupItems, item),
                    })}
                    onClick={() => onSelect(group, item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </React.Fragment>
        )
      })}
    </ul>
  );
};

export default MenuBody;

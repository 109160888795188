import React, {useState, useEffect, useContext} from "react";
import cx from "classnames";

import FullScreenButton from "../components/FullScreenButton";
import PageFooter from "./PageFooter";

import Firepad from "../components/Pad/Firepad.js";

import styles from "./Preview.module.css";
import { AuthContext } from "../Firebase/context";
import { paths } from "../Firebase/config"

import EditPad from "../components/File/EditPad.js";

function getfileUrl(file, isFullScreen) {
  return (
    file.poster_url ||
    (isFullScreen ? file.url.replace("/thumbs/", "/large/") : file.url)
  );
}

/* function numberToWord (number) {
  if (number === 1) return "one";
  if (number === 2) return "two";
  if (number === 3) return "three";
  if (number === 4) return "four";

  return "five"
} */

/* function PadStack(props) {
  let n = props.pageCount
  if (n<2) return <></>

  const divs = []

  for (let i = 1; i < n; i++) {
    divs.push({ x: i*(20/(n-1)), y: i*(20/(n-1)) });
  }

  const stack = divs.map( (div, index) => (
    <div key={index} className={cx(styles.padPageBackBackBack)} style={{bottom: div.x, right: div.y, zIndex: -index-1}}></div>
  ))

  return (<>{stack}</>)
} */

const Page = (props) => {
  const {
    file,
    isFullScreen,
    onOpen,
    onDeselect,
    pageIndex,
    pageTotal,
    side,
  } = props;

  const { activeEditId } = useContext(AuthContext);

  const [stackMode,setStackMode] = useState(true); //todo refactor/remove....

  const baseRef = `${paths.EDIT_STORAGE}/${activeEditId}/${file.ref}`


  // cleaner way

  if (isFullScreen) {
    return (
      <>
      <div className={cx(styles.Page, { [styles.fullScreen]: isFullScreen }, {[styles.padPage]: stackMode && !isFullScreen && file.doctype === 'pad' && file.pageCount>1})}>
        <FullScreenButton onClick={/* file.doctype==='pad' ? ()=>{setStackMode(!stackMode)} : */ onOpen} />

        { file.doctype === 'pad' ?
            <div className={styles.padWrapper}>
              <Firepad src={file} page={1} isFullScreen={isFullScreen} />
            </div>
          :
            <div className={styles.imgWrapper}>
              <img src={getfileUrl(file, isFullScreen)} alt="" />
            </div>
        }

        <div className={styles.title}>
          {file.title.replace(/ /g, "_")}
        </div>
        
        <PageFooter page={pageIndex + 3} pageTotal={pageTotal} />
      </div>

      <div className={cx(styles.Page, { [styles.fullScreen]: isFullScreen }, {[styles.padPage]: stackMode && !isFullScreen && file.doctype === 'pad' && file.pageCount>1})}>
        <FullScreenButton onClick={/* file.doctype==='pad' ? ()=>{setStackMode(!stackMode)} : */ onOpen} />

        { 
        <div className="AnnotationSection">
          <div>
            <EditPad reference={baseRef+'-a'} isLarge={isFullScreen}/>
          </div>
          <div style={{borderLeft:'1px dotted black'}}>
            <EditPad reference={baseRef+'-b'} isLarge={isFullScreen}/>
          </div>
        </div>
        }
        
        <PageFooter page={pageIndex + 3} pageTotal={pageTotal} />
      </div>
    </>
    )

  }

  return (
    <>
      <div className={cx(styles.Page, { [styles.fullScreen]: isFullScreen }, /* {[styles.padPage]: stackMode && !isFullScreen && file.doctype === 'pad' && file.pageCount>1} */)}>
        <FullScreenButton onClick={/* file.doctype==='pad' ? ()=>{setStackMode(!stackMode)} : */ onOpen} />

        { file.doctype === 'pad' ?
            <div className={styles.padWrapper}>
              <Firepad src={file} page={1} isFullScreen={isFullScreen} />
            </div>
          :
            <div className={styles.imgWrapper}>
              <img src={getfileUrl(file, isFullScreen)} alt="" />
            </div>
        }

        <div className={styles.title}>
          {file.title.replace(/ /g, "_")}
        </div>

        { /* (file.doctype!=='pad' || !side || side==='b') && */
        <div className="AnnotationSection">
          <div>
            <EditPad reference={baseRef+'-a'} isLarge={isFullScreen}/>
          </div>
          <div style={{borderLeft:'1px solid black'}}>
            <EditPad reference={baseRef+'-b'} isLarge={isFullScreen}/>
          </div>
        </div>
        }
        
        <PageFooter page={pageIndex + 3} pageTotal={pageTotal} />
        {onDeselect && <button onClick={onDeselect}>✕</button>}

        { /* stackMode
          && file.doctype === 'pad'
          && !isFullScreen
          && <PadStack pageCount={ 2 || file.pageCount || 1}/> */
        }
      </div>
      { !stackMode
        && file.doctype === 'pad'
        && !isFullScreen
        && [...Array(file.pageCount-1)].map( (val, index) => (
          <div className={cx(styles.Page)}>
            <div className={styles.padWrapper}>
              <Firepad src={file} page={index+2} isFullScreen={isFullScreen} />
            </div>
          </div>
        ))
      }
    </>
  );

};

export default Page;

import groupBy from "lodash.groupby";
import { EXTERNAL_URLS, S3_URL } from "../constants";

const uniq = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
const sortFn = (a, b) => {
  if (a === "research") {
    return 1;
  }

  if (b === "research") {
    return -1;
  }

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};
const sort = (arr, sortFn = undefined) => [...arr].sort(sortFn);
const getUrl = (file, prefix = "/thumbs") =>
  `${`${S3_URL}${prefix}/${file.Key.replace(/ /g, "+")}`}`;

const sortByDate = (a, b) => {
  const [year_a, month_a, day_a] = a.date.split(" ");
  const [year_b, month_b, day_b] = b.date.split(" ");

  const date_a = new Date(
    parseInt(`20${year_a}`, 10),
    parseInt(month_a, 10) - 1,
    parseInt(day_a, 10)
  );

  const date_b = new Date(
    parseInt(`20${year_b}`, 10),
    parseInt(month_b, 10) - 1,
    parseInt(day_b, 10)
  );

  return date_b - date_a;
};

function incrementOrReset(currentIdx, maxIdx) {
  if (currentIdx === maxIdx) {
    return 0;
  }

  return currentIdx + 1;
}

export function isSelected(selectedFiles, file) {
  const id = file.id || file[0].id;

  return selectedFiles.some((selectedFile) => selectedFile.id === id);
}

export function sortFiles(array) {
  if (array.length === 0) {
    return [];
  }

  const order = sort(
    uniq(
      array
        .map((file) => file.doctype || (file.length && file[0].doctype))
        .filter(Boolean)
    )
  );
  const maxOrderIdx = order.length - 1;

  let files = [...array];
  let result = [];
  let orderIdx = 0;

  while (result.length !== array.length) {
    const doctype = order[orderIdx];
    const nextFile = files.find((file) => {
      if (file.doctype === undefined) {
        try {
          return file[0].doctype === doctype;
        } catch (error) {
          
          console.error(`error getting doctype from file: ${file.Key}`);
          
          return true;
        }
      }
      
      return file.doctype === doctype;
    });

    if (nextFile === undefined) {
      orderIdx = incrementOrReset(orderIdx, maxOrderIdx);
      continue;
    }

    result.push(nextFile);
    files = files.filter((file) => {
      const nextFileId =
        nextFile.id === undefined ? nextFile[0].id : nextFile.id;

      if (file.id === undefined) {
        return file[0].id !== nextFileId;
      }

      return file.id !== nextFileId;
    });

    orderIdx = incrementOrReset(orderIdx, maxOrderIdx);
  }

  return result;
}

function getPage(rest) {
  const match = rest && rest.match(/-p[0-9]+/);

  if (!match) {
    return undefined;
  }

  return match[0].replace("-p", "");
}

export const transform = (files, prefix) => {
  const grouped = groupBy(
    files
      .map( file => parseFile(file, prefix) )
      .sort(sortByDate),
    (file) => (file.page ? file.keyWithoutPage : "normal")
  );

  const { normal, ...rest } = grouped;

  return [...(normal || []), ...Object.values(rest)];
};

export const parseFile = (file, prefix/*  = "/thumbs" */) => {
  if (file.doctype!=='pad') {
    const key = (file.Key || file.uniqueId || file.name || "").toLowerCase();
    const name = key
      .substring(key.lastIndexOf("/") + 1, key.length)
      .replace("_0_", "__");

    const ref = name.substring(0,name.lastIndexOf(".")); //for db

    const [date, project, location, author, doctype, rest] = name.split(
      "_"
    );
    const page = getPage(rest);
    // This is needed for grouping:
    // "9. SCRIPTS/21 01 24_HALL12_amsterdam_menzoshaly_invitation_01.pdf" &
    // "9. SCRIPTS/21 01 24_HALL12_amsterdam_menzoshaly_invitation_02.pdf" were combined
    // before, because the 01 / 02 parts were ignored
    const keyWithoutPage = key.replace(/-p[0-9]+/, '')
    const url = getUrl(file, prefix);
    const title = file.Title || [
      date,
      project ? project.toUpperCase() : "",
      location,
      author,
    ]
      .filter(Boolean)
      .join(" ");

    return {
      ref: ref,    //fix
      //pageCount: 1,                           //fix
      id: key,                              
      Key: key,
      name:name,
      keyWithoutPage,
      title,
      date,
      location,
      url,
      doctype,
      author,
      page,
      project: project && project.toUpperCase(),
      externalURL: EXTERNAL_URLS[key],
      //TO MAKE PADS HAVE EXTERNAL_URLS, comment out the above line and uncomment the line below
      //externalURL: doctype==='pad' ? `http://bit.ly/taat-pad#${title.toLowerCase().replace(/\s+/g,"_")}`: EXTERNAL_URLS[key],
    };    
  }
  if (file.doctype==='pad') {
    const key = file.Key;
    const name = 'test';
    //const uniqueId = file.ref;
    const doctype = file.doctype;
    const date = file.date;
    const project = file.project;
    const location = file.location;
    const author = file.author;
    const title = [
      date,
      project ? project.toUpperCase() : "",
      location,
      author,
    ]
      .filter(Boolean)
      .join(" ");

    return {
      ref: file.ref,    //fix
      //pageCount: 1,                           //fix
      id: key,                              
      //uniqueId: uniqueId,                     //fix
      Key: key,
      name:name,
      keyWithoutPage: key,
      title,
      date,
      location,
      url: undefined,
      doctype,
      author,
      project: project && project.toUpperCase(),
      externalURL: EXTERNAL_URLS[key],
      //TO MAKE PADS HAVE EXTERNAL_URLS, comment out the above line and uncomment the line below
      //externalURL: doctype==='pad' ? `http://bit.ly/taat-pad#${title.toLowerCase().replace(/\s+/g,"_")}`: EXTERNAL_URLS[key],
    };
    }
};

// export const getIdFromKey = key => {
//   let name = key.substring(key.lastIndexOf("/") + 1, key.length).replace("_0_", "__");
//   return name.substring(0,name.lastIndexOf("."));
// }

export const getFilters = (files) => ({
  project: sort(
    uniq(files.map((file) => file.project || file[0] && file[0].project).filter(Boolean)),
    sortFn
  ),
  location: sort(uniq(files.map((file) => file.location || file[0] && file[0].location).filter(Boolean))),
  author: sort(uniq(files.map((file) => file.author || file[0] && file[0].author).filter(Boolean))),
  doctype: sort(uniq(files.map((file) => file.doctype || file[0] && file[0].doctype).filter(Boolean))),
});
import React, { useCallback, useContext } from "react";
import { Redirect } from "react-router";
import { auth } from "../Firebase/config"
import { AuthContext } from "../Firebase/context"

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await auth.signInWithEmailAndPassword(email.value, password.value);
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );

  const { user } = useContext(AuthContext);

  if (user) {
    return <Redirect to={{ pathname: "/archive" }} />;
  }

  return (
    <div style={{background: 'rgb(230,230,230)', fontSize: '2em', width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <form onSubmit={handleLogin}>
        <label for="email">
          Email<br/>
        </label>
        <input style={{fontFamily: 'inherit', fontSize: 'inherit'}} name="email" type="email" placeholder="Email" />
        <br/>
        <br/>
        <label for="password">
          Password<br/>
        </label>
        <input style={{fontFamily: 'inherit', fontSize: 'inherit'}} name="password" type="password" placeholder="Password" />
        <br/>
        <br/>

        <button type="submit" style={{border: '1px solid black'}}>LOG IN</button>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";

import parse from 'html-react-parser';

import /* styles from  */ './PreviewFirepad.css';
import wrapperStyles from "../../Preview/Preview.module.css";

import {base, paths} from "../../Firebase/config.js"


function Repeat(props) {
  let items = [];
  for (let i = 0; i < props.numTimes; i++) {
    items.push(props.children(i));
  }
  return <div>{items}</div>;
}

function processHtmlPreview(html) {
  let contents = "<!DOCTYPE html>"
  html = html.substring(html.indexOf("<div>title: "), html.length)

  let title = html.substring(12, html.indexOf("</div><div>"))

  contents += `<div class="previewPadTitle">${title}</div>`

  contents += html.substring(html.indexOf("</div><div>")+6, html.length)

  return contents
}

const Firepad = ({ src, page, isExpanded, isFullScreen }) => {
  const [contents, setContents] = useState('loading..');
  const [fullContents, setFullContents] = useState('loading..');
  const [pageCount, setPageCount] = useState(1);

  useEffect( () => {
    var firepadRef = base.ref(paths.PAD_STORAGE).child(src.ref);
    console.log(`creating headless firepad ${src.ref}`)
    var headless = new window.Firepad.Headless(firepadRef);

    let start = (page-1)*2000;
    let end = start+2000;

    headless.getHtml( data => {

      setContents(processHtmlPreview(data).substring(start, end));
      setFullContents(processHtmlPreview(data));

      headless.getText( text => {
        setPageCount(Math.floor(text.length / 3000)+1); //temp hack to estimate page count
        //src.padContents = text; //sets the padContents of the file to the plain text version
        console.log(`disposing headless firepad ${src.ref}`)
        headless.dispose();
      });
    });
  }, []);

  useEffect( ()=> {
    console.log(`set ${src.ref} pageCount: ${pageCount}`)
  }, [pageCount]);

  return(
    <>
      <div className={isFullScreen || isExpanded ? "ExpandedSelectionPad" : "SelectionPad"}>
        {parse(isFullScreen ? fullContents : contents)}
      </div>
    </>
  );

  // return (
  //   <>
  //       { !isFullScreen ?
  //           <div className={isFullScreen || isExpanded ? styles.ExpandedSelectionPad : styles.SelectionPad}>
  //             {parse(contents)}
  //           </div>
  //         :
  //           <>
  //             <div className={wrapperStyles.Preview}>
  //               <div className={wrapperStyles.Body}>
  //                 <div className={wrapperStyles.expanded}>
  //                   <div className={wrapperStyles.wrapper}>
  //                     <Repeat numTimes={pageCount}>
  //                       {
  //                         (index)=>
  //                         <div key={index} className={wrapperStyles.Page}>
  //                           <div className={wrapperStyles.padWrapper}>
  //                             <Firepad src={src} page={index+1} isFullScreen={false} isExpanded />
  //                           </div>
  //                         </div>
  //                       }
  //                     </Repeat>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </>
  //       }

  //   </>
  // );
}

export default Firepad;

import React from "react";
import cx from "classnames";

import FullScreenButton from "../components/FullScreenButton";
import styles from "./Preview.module.css";

export default function EndPage({text, isFullScreen, onOpen }) {
  
  return (
    <div className={cx(styles.Page, styles.CoverPage, { [styles.fullScreen]: isFullScreen })}>
      {onOpen && <FullScreenButton onClick={onOpen} />}
      <ul>
        <li>{text}</li>
      </ul>
    </div>      
  );
}

import React, { useState, useEffect } from "react";

import parse from 'html-react-parser';
import { base, paths } from "../Firebase/config";
import styles from "../Home/Home.module.css";

const ReadOnlyPad = ({match, id, onClose}) => {
  const [content, setContent] = useState('loading...')

  useEffect( ()=> {
    var pad = new window.Firepad.Headless(
      base.ref(paths.PAD_STORAGE).child(match?.params?.id || id)
    )

    pad.getHtml(html=>setContent(html))
  }, [])

  return <>
    <div className={styles.Information} /* style={{fontSize: '1.5rem', margin: '1em'}} */>
    {onClose && <button className={styles.close} onClick={onClose}>
      ✕
    </button>}
      {parse(content)}
    </div>
  </>
}

export default ReadOnlyPad;

import { Dropbox } from "dropbox";

const DropboxSDK = new Dropbox({
  accessToken: process.env.REACT_APP_DROPBOX_TOKEN,
  fetch,
});

// Use me for debugging
// e.g. call me in App.js
export const getLink = async () => {
  try {
    const dropbox_key = "9. SCRIPTS/21 01 24_HALL12_amsterdam_menzoshaly_invitation_01.pdf"
    const { link } = await DropboxSDK.filesGetTemporaryLink({ path: `${process.env.REACT_APP_DROPBOX_FOLDER}/${dropbox_key}` });

    console.log(link)
  } catch (e) {
    console.error(e)
  }
}

export const getTemporaryLink = async (file) => {
  try {
    const parts = file.Key.split('/')
    const path = `${process.env.REACT_APP_DROPBOX_FOLDER}/${parts[0].toUpperCase()}/${parts[1].replace('.jpeg', '.mp4')}`
//    const path = `/B. TAAT/0. LIVE ARCHIVE/${parts[0].toUpperCase()}/${parts[1].replace('.jpeg', '.mp4')}`

    const { link } = await DropboxSDK.filesGetTemporaryLink({ path });

    return link;
  } catch (error) {
    console.error(error);
  }
};

import axios from "axios";
import { API_URL, S3_URL } from "./constants";

const download = (response) => {
  const linkSource = `${S3_URL}/pdf/${response.data}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = response.data;
  downloadLink.click();
};

export const getFiles = async () => axios.get(`${API_URL}/files`);

export const getCurrentFiles = async () =>
  axios.get(`${API_URL}/files?prefix=large/6. current`);

export const getSignedFiles = async (keys) =>
  axios.post(`${API_URL}/signed_files`, {
    keys,
  });

export const generatePDF = async (images) =>
  axios.post(`${API_URL}/pdf`, { images }).then(download);

export const uploadVideo = async (file, time) => {
  const parts = file.Key.split("/");
  const path = `/${parts[0].toUpperCase()}/${parts[1].replace(
    ".jpeg",
    ".mp4"
  )}`;

  return axios.post(`${API_URL}/screencapture`, {
    ...file,
    name: file.Key,
    time,
    path_display: path,
    path_lower: path.toLowerCase(),
  });
};

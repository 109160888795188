import React, { useEffect, useContext, useState } from "react";
import FullScreenButton from "../FullScreenButton";


import { AuthContext } from "../../Firebase/context";
import { base, paths } from "../../Firebase/config.js";
//import parse from 'html-react-parser';

import './Pad.codemirror.css';
import './Pad.firepad.css';

const Pad = ({ onOpen, file, isLarge, titleSetter }) => {
  const { user } = useContext(AuthContext);
  const [text, setText] = useState('');
  const [padTitle, setPadTitle] = useState('');

  let ref = file.ref

  useEffect( () => {
    var firepadRef = base.ref(paths.PAD_STORAGE).child(ref);
    console.log(`Initialising pad: ${firepadRef}`)

    var codeMirror = window.CodeMirror(
      document.getElementById(`${ref}-pad`),
      { lineWrapping: true, readOnly: !!user ? false : true, /*cursorBlinkRate: user ? 530 : -1*/ }
    );

    var firepad = window.Firepad.fromCodeMirror( firepadRef, codeMirror,
        {
          readOnly: user ? false : true,
          richTextToolbar: false,
          richTextShortcuts: user ? true : false,
        }
      );

    // Initialize contents.
    firepad.on('ready', () => {
      if (firepad.isHistoryEmpty()) {
        let date = ''
        let username = ''
        firepad.setHtml(`
          date: ${date}<br/>
          project: <br/>
          location: <br/>
          author: ${username}<br/>
          title: (please fill in)<br><br>
        `);
      } else {
        setText(firepad.getText());
      }

    });

    firepad.on('synced', () => {
      setText(firepad.getText());

    })

    return () => {
      console.log(`Deinitialising pad: ${firepadRef}`)
      firepad.dispose();
    }
  }, []);

  useEffect( ()=> {
    file.padTitle = padTitle; 
    console.log(file.padTitle);
  }, [padTitle]);

  useEffect( () => {
    setArchiveTitle()
    setPadTitle(parsePad('title'))

    file.pageCount = Math.floor(text.length / 3000)+1;
    //console.log(`pad ${file.ref} page count: ${file.pageCount}`)
    if (user) {
      base.ref(paths.PAD_METADATA).child(ref).update({
        characterCount: text.length
      });
    }   
  }, [text]);

  function setArchiveTitle() {

    if (text==='') return;
    if (!file) return;

    let title = parsePad('date')+' '+parsePad('project')+' '+parsePad('location')+' '+parsePad('author');
    if (title === file.title) return;

    titleSetter(title);
    file.title = title;
    console.log(`updated file.title: ${file.title}`)

    return file.title
  }

  function parsePad(yamlKey) {
    if (!text || text === undefined) return

    let key = `${yamlKey}: `

    if (text.split(key).length<2) return ''

    let val = text.split(key)[1];
    
    if (val.split("\n").length>1) {
      val = val.split("\n")[0];
    }

    if (val === '(please fill in)') {
      val = '';
    }

    if (yamlKey!=='title' && yamlKey!=='date') {
      val = val.replace(/\s+/g, '')
    }

    if (yamlKey==='title') file.padTitle = val;
    if (yamlKey==='project') file.project = val;

    if (user) {
      base.ref(paths.PAD_METADATA).child(ref).update({
        [yamlKey]: val,
      });
    }

    return val;
  }

  return (
    <>
      {onOpen && <FullScreenButton onClick={onOpen} />}
      <div className={isLarge ? "ExpandedPad" : "Pad"}>
        <div id={`${ref}-pad`}>
        </div>
        { !isLarge &&
          <>
            <div style={{zIndex: 10, position: 'absolute', width: '100%', bottom:0, paddingBottom:'1em', fontSize: '1.5rem', textTransform: 'uppercase', textAlign: 'center'}}>
              <span style={{/* background: '#000', color: '#fff',  */margin:0, padding: 0, paddingTop: '0.1em', height: '1em'}}>
                { padTitle }
              </span>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default Pad;

import React from "react";
import Dropdown from "../Dropdown";

import MobileFilters from "./MobileFilters";
import styles from "./Filters.module.css";

const Filters = (props) => {
  const { activeFilters, filters = [], onFilter, isMobile } = props;
  const show_clear_filters =
    Object.keys(filters).length > 1 &&
    Object.keys(activeFilters).some((key) => {
      return Boolean(activeFilters[key].length);
    });

  if (isMobile) {
    return (
      <MobileFilters
        filters={filters}
        activeFilters={activeFilters}
        onFilter={onFilter}
      />
    )
  }

  return (
    <div className={styles.Filters}>
      {Object.keys(filters).map((filter) => (
        <Dropdown
          key={filter}
          category={filter}
          items={filters[filter]}
          selectedItems={activeFilters[filter]}
          onSelect={onFilter}
        />
      ))}
      {show_clear_filters && (
        <button className={styles.clear} onClick={() => onFilter()}>
          ✕ clear
        </button>
      )}
    </div>
  );
};

export default Filters;

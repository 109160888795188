
import React from "react";
import LazyLoad from "react-lazyload";

import Pdf from "./Pdf";

const LazyPdf = (props) => (
  <LazyLoad offset={400} height={400}>
    <Pdf {...props} />
  </LazyLoad>
);

export default LazyPdf

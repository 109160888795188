import React, {useEffect, useState, useRef, useContext} from "react";
import cx from "classnames";
import { format } from "date-fns";
import { DATE_FORMAT_YYMMDD } from "../constants";

import FullScreenButton from "../components/FullScreenButton";
import styles from "./Preview.module.css";

import { AuthContext } from "../Firebase/context";

const CoverPage = ({ title, updateTitle, /* contributor, authors = [],  */isFullScreen, onOpen, pageTotal }) => {
  const today = format(new Date(), DATE_FORMAT_YYMMDD);

  //const listAuthors = authors && authors.map((a,i)=> `${a}${i<authors.length-1?', ':''}`)
  const listArray = (arr) => arr.map((a,i)=> `${a}${i<arr.length-1?', ':''}`)

  const [localTitle, setLocalTitle] = useState('')

  const timeoutRef = useRef(null);  
  const { user, activeEdit } = useContext(AuthContext);

  useEffect(()=>{
    if (title===localTitle) return

    setLocalTitle(title)
    console.log('title received from server')
  }, [title]);

  useEffect(()=>{
    if (!user && activeEdit) return

    if (timeoutRef.current !== null) {          // IF THERE'S A RUNNING TIMEOUT
      clearTimeout(timeoutRef.current);         // THEN, CANCEL IT
    }

    timeoutRef.current = setTimeout(()=> {      // SET A TIMEOUT
      timeoutRef.current = null                // RESET REF TO NULL WHEN IT RUNS
      updateTitle(localTitle)         
    },500);     
  }, [localTitle]);

  function handleTitleChange(e) {
    setLocalTitle(e.target.value)
    
    console.log(e.target.value)
  }

  
  return (
    <div className={cx(styles.Page, styles.CoverPage, { [styles.fullScreen]: isFullScreen })}>
      {onOpen && <FullScreenButton onClick={onOpen} />}
      <ul>
        <li>Edit: {activeEdit?.name || today}</li>
        <li>Title: <input type="text" onChange={handleTitleChange} value={localTitle} /></li> 
        <li>Pages: {pageTotal}</li>
        <li>Authors: {activeEdit?.authors?.length ? listArray(activeEdit.authors) : ''} </li>
        <li>Editor: {activeEdit ? activeEdit.editor : ''}</li>
      </ul>
    </div>      
  );
}

export default CoverPage;

import React, { useRef, useState } from "react";
import cx from "classnames";

import useOnOutsideClick from "../../utils/useOnOutsideClick";
import includes from "../../utils/includes";
import { FILTER_TRANSLATIONS } from "../../constants";

import styles from "./Dropdown.module.css";

const BODY_CLASS = "dropdown-open";

const Dropdown = ({ category, selectedItems = [], items = [], onSelect }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  function toggle(event) {
    event.preventDefault();

    if (!isOpen) {
      document.body.classList.add(BODY_CLASS);
      return setIsOpen(true);
    }

    document.body.classList.remove(BODY_CLASS);
    return setIsOpen(false);
  }

  useOnOutsideClick(ref, (event) => {
    if (isOpen) {
      toggle(event);
    }
  });

  return (
    <div className={styles.Dropdown} ref={ref}>
      <div className={styles.Button} onClick={toggle}>
        {FILTER_TRANSLATIONS[category]}
        {Boolean(selectedItems.length) && (
          <span>{`(${selectedItems.length})`}</span>
        )}
      </div>
      {isOpen && (
        <ul>
          {items.map(item => (
            <li
              key={item}
              className={cx({
                [styles.selected]: includes(selectedItems, item)
              })}
              onClick={() => onSelect(category, item)}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Dropdown.propTypes = {};

export default Dropdown;

import React, { useEffect, useRef } from "react";
import { getTemporaryLink } from "../../dropbox";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/themes/dist/city/index.css";

import styles from "./File.module.css";

function getTime(time) {
  const minutes = `0${Math.floor(time / 60)}`.slice(-2);
  const seconds = `0${Math.round(time - minutes * 60)}`.slice(-2);

  return `00:${minutes}:${seconds}`;
}

async function getAspectRatio(src) {
  return new Promise(resolve => {
    const img = new Image();

    img.onload = () => {
      const { width, height } = img;
      resolve(`${width}:${height}`)
    }

    img.src = src;
  });
}

const Video = (props) => {
  const { Key, onPause, poster } = props;
  const playerRef = useRef();

  useEffect(() => {
    async function initialize() {
      const src = await getTemporaryLink({ Key });
      const aspectRatio = await getAspectRatio(playerRef.current.poster);

      const player = videojs(playerRef.current, {
        controls: true,
        aspectRatio,
      }, () => {
        player.src({ src, type: "video/mp4" });
        player.on("pause", () => {
          onPause(getTime(player.currentTime()));
        });
      });

      return () => {
        player.dispose();
      };
    }

    if (playerRef) {
      initialize();
    }
  }, [Key]); // eslint-disable-line

  return (
    <div className={styles.Video}>
      <div data-vjs-player>
        <video
          className="video-js vjs-theme-city vjs-big-play-centered"
          poster={poster}
          preload="none"
          ref={playerRef}
        />
      </div>
    </div>
  );
};

export default Video;

import includes from "./includes";

export default (files, selection) =>
  Object.keys(selection).reduce((filteredFiles, key) => {
    return filteredFiles.filter((file) => {
      if (!selection[key].length) {
        return true;
      }

      try {
        return includes(
          selection[key].map((selection) => selection.toLowerCase()),
          (file.id === undefined ? file[0][key] : file[key]).toLowerCase()
        )
      } catch (e) {
        console.error(e);
        return false;
      }
    });
  }, files);

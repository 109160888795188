import React from "react";

import styles from "./Carousel.module.css"

const Image = ({ url }) => (
  <div
    className={styles.image}
    style={{ backgroundImage: `url(${url})` }}
  />
);

export default Image;

import React, { useRef, useState } from "react";
import cx from "classnames";

import { ReactComponent as Hamburger } from "../../assets/hamburger.svg"
import useOnOutsideClick from "../../utils/useOnOutsideClick";
import { DISABLED_CLASS } from "../../constants";

import MenuBody from "./MenuBody";

import styles from "./Menu.module.css";

const Menu = ({ selectedItems = [], items = {}, onSelect }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);

  function toggle(event) {
    event.preventDefault();

    if (!isOpen) {
      document.body.classList.add(DISABLED_CLASS);
      return setIsOpen(true);
    }

    document.body.classList.remove(DISABLED_CLASS);
    return setIsOpen(false);
  }

  useOnOutsideClick(ref, (event) => {
    if (isOpen) {
      toggle(event);
    }
  });

  return (
    <div className={cx(styles.Menu)} ref={ref}>
      <div className={styles.Button} onClick={toggle}>
        <span>{isOpen ? "✕" : <Hamburger />}</span>
        {Boolean(selectedItems.length) && (
          <span>{`(${selectedItems.length})`}</span>
        )}
      </div>
      <MenuBody
        isOpen={isOpen}
        items={items}
        selectedItems={selectedItems}
        onSelect={onSelect}
      />
    </div>
  );
};

export default Menu;

import React, { useCallback } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import cx from "classnames";

import CoverPage from "./CoverPage";
import ContentPage from "./ContentPage";
import EndPage from "./EndPage";
import Page from "./Page";

import styles from "./Preview.module.css";

const SortablePage = SortableElement(Page);

const SortablePages = SortableContainer(({ title, updateTitle, editId, contributor, files, onOpen, onDeselect }) => {
  return (
    <div className={styles.Body}>
      <CoverPage
        title={title}
        updateTitle={updateTitle}
        contributor={contributor}
        editId={editId}
        onOpen={() => onOpen(0)}
        pageTotal={files.length + 3}
      />
      <ContentPage
        files={files}
        onOpen={() => onOpen(1)}
        pageTotal={files.length  + 3}
      />
      {files.map((file, index) => (
        <SortablePage
          key={file.id}
          editId={editId}
          index={index}
          pageIndex={index}
          pageTotal={files.length + 2}
          file={file}
          onDeselect={() => onDeselect(index)}
          onOpen={() => onOpen(index + 2)}
        />
      ))}
      <EndPage
        text={'TAAT 2021'}
        onOpen={() => onOpen(files.length+2)}
      />
    </div>
  );
});

const Grid = ({ title, updateTitle, files, onOpen, onReorder, onDeselect }) => {
  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => onReorder(arrayMove(files, oldIndex, newIndex)),
    [files, onReorder]
  );

  const onDeselectFile = useCallback(
    index => onDeselect(files.slice(0, index).concat(files.slice(index + 1))),
    [files, onDeselect]
  );

  return (
    <SortablePages
      title={title}
      updateTitle={updateTitle}
      axis="xy"
      files={files}
      onOpen={onOpen}
      onSortEnd={onSortEnd}
      onDeselect={onDeselectFile}
      helperClass={cx(styles.Page, styles.dragging)}
    />
  );
};

export default Grid;

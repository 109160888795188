import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { S3_URL } from "../constants";

import { generatePDF } from "../api";

import Carousel from "../components/Carousel";
import Header from "../components/Header";

import ContentPage from "./ContentPage";
import CoverPage from "./CoverPage";
import EndPage from "./EndPage";
import Grid from "./Grid";
import Page from "./Page";

import { base, paths } from "../Firebase/config";
import { AuthContext } from "../Firebase/context";
import { parseFile } from "../utils/file";

import { format, isThisMinute } from "date-fns";
import { DATE_FORMAT_YYMMDD, SELECTION_PATH } from "../constants";

import styles from "./Preview.module.css";

function arraysEqual(arr1, arr2) {
  if(arr1.length !== arr2.length)
      return false;
  for(var i = arr1.length; i--;) {
      if(arr1[i] !== arr2[i])
          return false;
  }

  return true;
}


const Preview = ({ editCount, files = [], screencaptures = [], onClose, onReorder, onDeselect }) => {
  const [lightbox_index, setLightboxIndex] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user, username, meta, activeEdit, activeEditId, setActiveEditId } = useContext(AuthContext);
  const is_lightbox_open = lightbox_index !== false;

  const [editTitle, setEditTitle] = useState('');

  const today = format(new Date(), DATE_FORMAT_YYMMDD);

  const history = useHistory()

  useEffect( () => {
    console.log('selected files: ', files)
  }, [])

  useEffect( () => {
    console.log('activeEdit', activeEdit)
    console.log('activeEditId', activeEditId)

    if (!activeEdit) return

    history.push(SELECTION_PATH+'/'+activeEditId)

    console.log(`initaliasing listener for ${paths.EDIT_METADATA}/${activeEdit.key}`);

    if (files.length && user) {
      // this works but is dangerous ---- how should we handle this

      let sel = files.map(f=>f.id)
      let store = activeEdit.FILES

      let selhasstorenot = sel.filter(f=>!store.includes(f))
      let storehasselnot = store.filter(f=>!sel.includes(f))


      let fixedlist = store.filter(f=>!storehasselnot.includes(f)).concat(selhasstorenot)

      if (!arraysEqual(activeEdit.FILES, fixedlist)) {
        console.log('sel: ', files)
        console.log('stored: ', activeEdit.FILES)
        console.log('selhasstorenot: ', selhasstorenot)
        console.log('storehasselnot: ', storehasselnot)
     
        console.log('fixedlist: ', fixedlist)
  
        console.log(`calling onFileListUpdatedLocally(fixedlist), fixedlist=`, fixedlist)
        onFileListUpdatedLocally(fixedlist)
      }

      /*//////////////////////////////////

      CURRENT STATUS;;;;

      FIXEDLIST IS GOOD, BUT ERR ON UPDATING


      //////////////////////////////////*/
 
    } 

    console.log(`pushing update to base.ref(${paths.EDIT_METADATA}/${activeEditId})`)
    base.ref(`${paths.EDIT_METADATA}/${activeEditId}`).on('value', snapshot => {
      let res = snapshot.val()

      if (res === null) {
        console.log(`The requested edit id either doesn't exist, can't be found, maybe because it was just deleted. Redirecting...`)
        setActiveEditId(null)
        onClose()
        return
      }

      console.log(`db listener received snapshot:`)
      console.log(res)

      if (res.FILES) {  
        let fileList = res.FILES.map(f=>parseFile(
          f.substr(0,1)==="-" ? //is this reference a pad?
          {
            Key: f,
            ref: f,
            doctype: 'pad',
            date: meta[f]?.date || '?',
            project: meta[f]?.project|| '?',
            location: meta[f]?.location|| '?',
            author: meta[f]?.author || '?',
          }
          :
          { Key: f }
        ))

        //console.log(`db listener reordering files:`);
        //console.log(fileList);
        
        onReorder(fileList);
      }

      if (res.title) setEditTitle(res.title);
    })

    return () => {
      base.ref(`${paths.EDIT_METADATA}/${activeEditId}`).off()
      console.log('reference listener off')
    }

  }, [activeEdit]);

  const onSaveNewEdit = async () => {
    const newId = base.ref(paths.EDIT_METADATA).push().getKey()

    await onFileListUpdatedLocally(files, newId, {editor: username})
    setActiveEditId(newId)
    //history.push(`${SELECTION_PATH}/${newId}`)
  }

  function onEditTitleUpdatedLocally(title){
    if (!activeEdit) {
      setEditTitle(title)
    }
    if (activeEdit && isLoggedIn()) {
      if (title === activeEdit.title) return
      base.ref(`${paths.EDIT_METADATA}/${activeEditId}`).update({
        title: title,
        authors: [...new Set(activeEdit.authors.concat(username))],
      });  
    }
  }

  function isLoggedIn() {
    if (!user) {
      alert("Sorry, you need to be signed in to perform these edits. Please email info@taat-projects.com to request editing access.")
    }
    return user;
  }
  
  function onFileListUpdatedLocally(fileArray, targetEditId, optionalObj={}) {
    console.log("onFileListUpdatedLocally called, fileArray: ", fileArray)
    let id = targetEditId || activeEditId;

    if (!id) {
      onReorder(fileArray)
      return
    }

    // if logged in, push changes to db
    if (isLoggedIn()) {
      return new Promise((resolve, reject)=>{
        console.log(`onFileListUpdatedLocally sees logged in, pushing changes to db edit: ${id}`)
  
        let FILES_list = {}
        fileArray.map((f,index)=>{
          FILES_list[index] = f.Key || f;
        })

        console.log('FILES_list', FILES_list);
  
        base.ref(`${paths.EDIT_METADATA}/${id}`).update({
          lastEdited: today,
          authors: activeEdit ? [...new Set(activeEdit.authors.concat(username))] : [username],
          editor: activeEdit?.editor || username,
          title: editTitle,
          FILES: FILES_list, 
          ...optionalObj
        }, (error) => {
          if (error) {
            console.log(`onFileListUpdatedLocally failed to update ${paths.EDIT_METADATA}/${id}`)
            reject('failed to update')
          } else {
            console.log(`onFileListUpdatedLocally pushed to ${paths.EDIT_METADATA}/${id}`)
            resolve('sucessfully pushed')
          }
        })  
      })
    }
  }

  const getSlides = (files) => {
    return [
      <CoverPage title={editTitle} updateTitle={onEditTitleUpdatedLocally} pageTotal={files.length + 3} isFullScreen />,
      <ContentPage files={files} pageTotal={files.length + 3} isFullScreen />,
      ...files.map((file, index) => (
        <Page
          file={file}
          pageIndex={index}
          pageTotal={files.length + 2}
          isFullScreen
        />
      )),
      <EndPage text='TAAT 2022' isFullScreen/>
    ];
  }

  const isLoading = Object.values(screencaptures).some(
    (capture) => capture === "loading"
  );

  const extendedFiles = files.map((file) => ({
    ...file,
    poster_url:
      screencaptures[file.Key] && screencaptures[file.Key] !== "loading"
        ? `${S3_URL}/video${screencaptures[file.Key]}`
        : undefined,
  }));

  const close = useCallback(() => {
    setLightboxIndex(false);
  }, [setLightboxIndex]);

  const fetchPadHtml = async (path, key) => {
    return new Promise((resolve,reject) => {
      const ref = base.ref(path).child(key);
  
      const pad = window.Firepad.Headless(ref)
    
      pad.getHtml(html=>{
        resolve(html)
        pad.dispose()
      })
    })   
  }

  function processPadHtmlToPadArtefact(data) {
    //hacky coversion of firepad-richtext-html, parsing title, and following html

    let html = "<!DOCTYPE html>"
    data = data.substring(data.indexOf("<div>title: "), data.length)
  
    let title = data.substring(12, data.indexOf("</div><div>")).replace(/&nbsp;/g, '')
 
    //remove metadata from html to print
    html += data.substring(data.indexOf("</div><div>")+6, data.length)
  
    return { title, html }
  }

  const print = useCallback(async () => {

    console.log("PRINT CALLED")

    const artefacts_unresolved = extendedFiles.map(async(file) => ({
      key: file.poster_url || file.url,
      title: file.title,
      doctype: file.doctype,
      edits: {
        a: await fetchPadHtml(`${paths.EDIT_STORAGE}/${activeEditId}`, file.ref + '-a'),
        b: await fetchPadHtml(`${paths.EDIT_STORAGE}/${activeEditId}`, file.ref + '-b'),
      } || '',
      pad: file.doctype==='pad' ? processPadHtmlToPadArtefact(await fetchPadHtml(paths.PAD_STORAGE, file.ref)): '',
    }))
    const artefacts = await Promise.all(artefacts_unresolved)

    let data = {
      metadata: {
        date: activeEdit ? activeEdit.lastEdited : 'today',
        authors: activeEdit ? activeEdit.authors : [],
        editor: activeEdit ? activeEdit.editor : '',
        title: editTitle
      },
      artefacts: artefacts
    }

    console.log(':::::::::: request sent to pdfmake: ', data);

    try {
      setIsDownloading(true);
      await generatePDF(
        data
      );
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
      setIsError(true);
      console.log(e);
    }

  }, [extendedFiles]);

  return (
    <React.Fragment>
      <div className={styles.Preview}>
        <Header
          onSaveNewEdit={onSaveNewEdit}
          onClose={onClose}
          onPrint={print}
          isDownloading={isLoading || isDownloading}
          isError={isError}
          style={{ backgroundColor: "#000" }}
          files={files}
        />
        {!isLoading && (
          <Grid
            title={editTitle}
            updateTitle={onEditTitleUpdatedLocally}
            files={extendedFiles}
            onReorder={onFileListUpdatedLocally/*onReorder*/}
            onOpen={setLightboxIndex}
            onDeselect={onFileListUpdatedLocally/*onDeselect*/}
          />
        )}
      </div>

      {is_lightbox_open && (
        <Carousel
          initialSlide={lightbox_index}
          slides={getSlides(extendedFiles)}
          onClose={close}
          isFullScreen
        />
      )}
    </React.Fragment>
  );
};

export default Preview;

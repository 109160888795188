import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { trackWindowScroll } from "react-lazy-load-image-component";

import InfiniteScroll from 'react-infinite-scroll-component';

import { DISABLED_CLASS, ARCHIVE_PATH, SELECTION_PATH } from "../constants";
import { uploadVideo } from "../api";
import { isSelected } from "../utils/file";
import { useFiles } from "../hooks/useFiles";

import Carousel from "../components/Carousel";
import Header from "../components/Header";
import Preview from "../Preview";

import { base } from "../Firebase/config"
import { AuthContext } from "../Firebase/context"

import styles from "./Archive.module.css";

import File from "../components/File";


//const File = React.lazy(()=>import('../components/File'))

function findNested(files, id) {
  return files.flat().find((file) => file.id === id);
}

const Archive = () => {
  const {
    activeFilters,
    fetchFiles,
    filteredFiles,
    filters,
    isLoading,
    onFilter,
    onSelectFile,
    screencaptures,
    selectedFiles,
    setScreencaptures,
    setSelectedFiles,
  } = useFiles();

  const [lightboxId, setLightboxId] = useState(false);
  const isLightboxOpen = lightboxId !== false;
  const lightboxIndex =
    lightboxId !== false
      ? selectedFiles.map((file) => file.id).indexOf(lightboxId)
      : -1;

  const [visibleFilesLen, setVisibleFilesLen] = useState(20);
  const { activeEditId } = useContext(AuthContext)

  const location = useLocation();
  const history = useHistory();

  //const CURRENT_SELECTION = 0;

  const isOpen = location.pathname.includes(SELECTION_PATH); //location.pathname === SELECTION_PATH;

  const onScreencapture = async (file, time) => {
    setScreencaptures({ ...screencaptures, [file.Key]: "loading" });

    try {
      const { data } = await uploadVideo(file, time);

      setScreencaptures({ ...screencaptures, [file.Key]: data });
    } catch (error) {
      console.error(error);
      setScreencaptures({ ...screencaptures, [file.Key]: undefined });
    }
  };


  function toggleEditView(id) {
    if (!isOpen) {
      // setSelectedFiles([]); //clear selection
      // setActiveEditId(id)
      if (!activeEditId)
        history.push(`${SELECTION_PATH}/current`)
      else
        history.push(`${SELECTION_PATH}/${id}`)
    } else {
      history.push(ARCHIVE_PATH)
    }
  }

  useEffect(() => {
    isOpen || isLightboxOpen
      ? document.body.classList.add(DISABLED_CLASS)
      : document.body.classList.remove(DISABLED_CLASS);

    return () => document.body.classList.remove(DISABLED_CLASS);
  }, [isOpen, isLightboxOpen]);

  useEffect(() => {
    fetchFiles();
  }, []); // eslint-disable-line

  return (
    <div className={styles.Archive}>
      {/* SELECTION VIEW */
       isOpen && (
        <Preview
          files={selectedFiles}
          screencaptures={screencaptures}
          onClose={toggleEditView/*() => history.push(ARCHIVE_PATH)*/}
          onReorder={setSelectedFiles}
          onDeselect={setSelectedFiles}
        />
      )}
      {/* FILTER VIEW */
       !isOpen && (
        <Header
          activeFilters={activeFilters}
          filters={filters}
          files={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          isLoading={isLoading}
          onFilter={onFilter}
          onPreview={toggleEditView /*() => history.push(SELECTION_PATH)*/}
          style={{ backgroundColor: "#FFF" }}
        />
      )}
      {/* EXPANDED VIEW */
       isLightboxOpen && (
        <Carousel
          initialSlide={lightboxIndex === -1 ? 0 : lightboxIndex}
          slides={
            lightboxIndex !== -1
              ? selectedFiles.map((file) => (
                  <File file={file} key={lightboxId} isLarge />
                ))
              : [
                  <File
                    file={findNested(filteredFiles, lightboxId)}
                    key={lightboxId}
                    isLarge
                  />,
                ]
          }
          onClose={() => setLightboxId(false)}
          isFullScreen
        />
      )}
      
        <InfiniteScroll
          dataLength={visibleFilesLen} //This is important field to render the next data
          next={()=>setVisibleFilesLen(len=>len+20)}
          hasMore={visibleFilesLen < filteredFiles.length}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              {';>'}
            </p>
          }
        >
          <div className={styles.Body}>
          {filteredFiles.map((file, index) => index < visibleFilesLen && (   
            <File
              file={file}
              key={file.id || file[0].id}
              onClick={onSelectFile}
              onOpen={(id, externalURL) =>
                !!externalURL
                  ? window.open(externalURL, "_blank")
                  : setLightboxId(id)
              }
              onScreencapture={onScreencapture}
              selectedFiles={file.id === undefined ? selectedFiles : []}
              isSelected={isSelected(selectedFiles, file)}
            />
          ))}
          </div>
        
        </InfiniteScroll>
      
    </div>
  );
};

export default trackWindowScroll(Archive);

import React from "react";
import { isSelected } from "../../utils/file";

import File from "./File";
import FileStack from "../FileStack/FileStack";
import FullScreenButton from "../FullScreenButton";

import styles from "./File.module.css";

const sortByPage = (a, b) => parseInt(a.page, 10) - parseInt(b.page, 10);

const Pdf = ({ files, isExpanded, onOpen, onToggleExpand, onSelect, selectedFiles }) => {
  const sortedFiles = [...files].sort(sortByPage);

  return (
    <div className={styles.pdf}>
      <FullScreenButton
        onClick={onToggleExpand}
        top={
          sortedFiles.length >= 5 ? `${5 * 9}px` : `${sortedFiles.length * 5}px`
        }
      />
      <FileStack files={sortedFiles} />

      {isExpanded && (
        <div className={styles.expanded}>
          <button className={styles.close} onClick={onToggleExpand}>
            ✕
          </button>
          <div className={styles.wrapper}>
            {sortedFiles.map((file) => (
              <File
                file={file}
                key={file.id}
                isSelected={isSelected(selectedFiles, file)}
                onClick={() => onSelect(file)}
                onOpen={() => onOpen(file.id)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Pdf;

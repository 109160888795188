import React, { useContext} from "react";
import styles from "./Header.module.css";
import withSizes from "react-sizes";

import { ReactComponent as Arrow } from "../../assets/open.svg";
import { FACEBOOK } from "../../constants";

import Filters from "../Filters";

import { AuthContext } from "../../Firebase/context";

import { useFiles } from "../../hooks/useFiles";
import EditsDropdown from "../EditsDropdown";

const Header = (props) => {

  const {
    activeFilters,
    files = [],
    filters = [],
    isDownloading,
    isLoading,
    isMobile,
    isError,
    onClose,
    onFilter,
    onPreview,
    onPrint,
    style,
    onSaveNewEdit,
    setSelectedFiles,
  } = props;

  const { addPad } = useFiles();

  const { user, activeEditId, setActiveEditId, onDeleteEdit } = useContext(AuthContext);
  
  if (isLoading) {
    return (
      <div className={styles.Header}>
        <div className={styles.loading}>loading..</div>
      </div>
    );
  }

  function onNewPad() {
    addPad();
  }

  function onCloseEdit() {
    setSelectedFiles([]);
    setActiveEditId(null); 
  }

  return (
    <div className={styles.Header} style={style}>
      {onPreview && !isMobile && (
        <a className={styles.home} href="/">
          <Arrow />
        </a>
      )}
      {onPreview && (
        <Filters
          activeFilters={activeFilters}
          filters={filters}
          isMobile={isMobile}
          onFilter={onFilter}
        />
      )}
      {onPreview && !isMobile && (
        <div className={styles.agenda}>
          <a href={FACEBOOK} target="_blank" rel="noopener noreferrer">agenda</a>
        </div>
      )}
      <div
        className={styles.navigation}
        style={onPrint ? { width: "100%", color: "#FFF" } : {}}
      >
        {onPreview && user && (
          <button title="add pad" style={{marginRight:'0.5em'}} onClick={onNewPad}>
          +
          </button>
        )}
        {onPreview && activeEditId && (
          <button title="close selection" style={{marginRight:'0.5em'}} onClick={onCloseEdit}>
            ✕
          </button>  
        )}
        {onPreview && (
          <EditsDropdown
            numFilesSelected = {files.length}
            onSelect = {onPreview}
            setSelectedFiles = {setSelectedFiles}
          />  
        )}
        {onPrint && user && (
          <>
          {!activeEditId && <button onClick={onSaveNewEdit}>
            save as new edit
          </button>}
          {activeEditId && <button onClick={onDeleteEdit}>
            delete edit
          </button>}
          </>
        )}
        {onPrint && (
          <button onClick={onPrint} disabled={isDownloading}>
            {isDownloading ? "loading.." : "save as pdf"}
          </button>
        )}
        {isError && (
          <button disabled>
            An error occurred
          </button>
        )}
        {onClose && (
          <button className={styles.close} onClick={onClose}>
            ✕
          </button>
        )}
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 992,
});

export default withSizes(mapSizesToProps)(Header);

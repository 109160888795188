import React, { useEffect, useContext, useState, cloneElement } from "react";

import { base } from "../../Firebase/config"
import { AuthContext } from "../../Firebase/context";

import './EditPad.codemirror.css';
import './EditPad.firepad.css';

//EditPad aka AnnotationPad , for annotating in the edit zone

const EditPad = ({alt, onOpen, reference, isLarge}) => {
  const { user } = useContext(AuthContext);

  //const getDivId = `${reference.split('ANNOTATIONS/')[1].replace(/ /g,"_")}-${side}-pad-${isLarge ? 'exp' : 'sm'}`
  const divRef = `${reference.replace(/ /g,"_")}-pad-${isLarge ? 'exp' : 'sm'}`

  useEffect( () => {
    var firepadRef = base.ref(reference);
    console.log(`Initialising pad '${firepadRef}' on div id='${divRef}'`)

    var codeMirror = window.CodeMirror(
      document.getElementById(divRef),
      { lineWrapping: true, readOnly: !!user ? false : true }
    );

    var firepad = window.Firepad.fromCodeMirror( firepadRef, codeMirror,
        {
          readOnly: user ? false : true,
          richTextToolbar: false,
          richTextShortcuts: user ? true : false, 
        }
      ); 

    // codeMirror.on('change', (e) => {
    //   console.log(e);
    //   console.log("^^ code mirror changed. scroll info:");
    //   console.log(codeMirror.getScrollInfo())
    // });

    return () => {
      console.log('Deinitialising pad: ', firepadRef)
      firepad.dispose();
    }
  }, []);


  return (
    <>
      <div className={isLarge ? "ExpandedEditPad" : "EditPad"}>
        <div id={divRef}>

        </div>
      </div>
    </>
  );
}

export default EditPad;

import React from "react";
import styles from "./FullScreenButton.module.css"

const FullScreenButton = ({ onClick, top }) => (
  <button className={styles.btn} onClick={onClick} style={{ top }} />
);

FullScreenButton.propTypes = {
};

export default FullScreenButton;

import React, { useEffect, useState } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import WithSizes from "react-sizes";

import Carousel from "../components/Carousel";
import Information from "./Information";
import styles from "./Home.module.css";

import { ReactComponent as SoundOn } from "../assets/sound-on.svg";
import { ReactComponent as SoundOff } from "../assets/sound-off.svg";

import { getCurrentFiles } from "../api";
import { transform } from "../utils/file";

const Home = ({ isMobile }) => {
  const [informationOpen, setInformationOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    async function fetchFiles() {
      const { data: files } = await getCurrentFiles();

      //console.log(transform(files, "/large"));
      setFiles(transform(files, "/large"));
    }

    fetchFiles();
  }, []);

  return (
    <div>
      {informationOpen && (
        <Information onClose={() => setInformationOpen(false)} />
      )}
      <div className={styles.wrapper}>
        {!informationOpen && (
          <React.Fragment>
            <h1>
              {isMobile
                ? 'TAAT live archive'
                : 'welcome to the TAAT live archive'}
              </h1>
            <Link className={styles.link} to="/archive">
              enter live archive
            </Link>
            {/* <Link className={styles.link} to="/archive">
              enter live agora
            </Link> */}
          </React.Fragment>
        )}

        <Carousel
          autoPlay
          isMuted={isMuted}
          initialSlide={0}
          files={files}
        />
        {!informationOpen && (
          <button
            className={cx(styles.link, styles.right)}
            onClick={() => setInformationOpen(true)}
          >
            about
          </button>
        )}

        <button className={styles.sound} onClick={() => setIsMuted(!isMuted)}>
          {isMuted ? <SoundOff /> : <SoundOn />}
        </button>
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
});

export default WithSizes(mapSizesToProps)(Home);

export const API_URL =
  "https://kueam2nam7.execute-api.eu-central-1.amazonaws.com/dev"; //ma
  //"https://s5vleglh20.execute-api.eu-central-1.amazonaws.com/dev";
export const S3_URL =
  "https://s3.eu-central-1.amazonaws.com/ma.com.serverless.taat-dev";
  //"https://s3.eu-central-1.amazonaws.com/com.serverless.taat-dev";

export const DATE_FORMAT = "dd.MM.yyyy";
export const DISABLED_CLASS = "scroll-disabled";
export const FACEBOOK = "https://www.facebook.com/pg/taatprojects/events";

export const ARCHIVE_PATH = "/archive";
export const SELECTION_PATH = "/archive/edit";
export const DATE_FORMAT_YYMMDD = "yy MM dd";

export const FILTER_TRANSLATIONS = {
  project: "project",
  location: "location",
  author: "contributor",
  doctype: "doctype",
};

// Files with specific external URLs to open when clicked
// Object with key: File Key (or id), value: URL to open
export const EXTERNAL_URLS = {
  "2. photos/17 08 10_khorii_rimburg_swagerman_webdoc_01.jpg": "http://khor2.taat-projects.com",
  "20 11 18_research_0_taat_photo_01.jpg": "https://www.researchcatalogue.net/view/849232/865043",
  "20 11 18_research_0_taat_photo_02.jpg": "https://www.researchcatalogue.net/view/964187/964218",
};

// const DEV_FIREBASE_CONFIG = {
//   apiKey: "AIzaSyBZj2cqmbxImeZKwYH8I4Y9KJJz07nI5fU",
//   authDomain: "ma-pad.firebaseapp.com",
//   databaseURL: "https://ma-pad-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "ma-pad",
//   storageBucket: "ma-pad.appspot.com",
//   messagingSenderId: "1088161721423",
//   appId: "1:1088161721423:web:020d5f67072e4e203d8e3a"
// };

const REAL_FIREBASE_CONFIG = {
  apiKey: "AIzaSyCghep4568vyRviBHUnDOkuOdRp4cnjCrQ",
  authDomain: "ma-firepad.firebaseapp.com",
  databaseURL: "https://ma-firepad-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ma-firepad",
  storageBucket: "ma-firepad.appspot.com",
  messagingSenderId: "692806641626",
  appId: "1:692806641626:web:9e73237fb320238e0f82e3"
}



export const FIREBASE_CONFIG = REAL_FIREBASE_CONFIG; //DEV_FIREBASE_CONFIG;

import { useMemo, useState, useEffect, useContext } from "react";

import { getFilters, isSelected, transform, sortFiles, parseFile} from "../utils/file";
import filter from "../utils/filter";
import includes from "../utils/includes";

import { getFiles } from "../api";

import { base, paths } from "../Firebase/config.js"
import { AuthContext } from "../Firebase/context.js"

export function useFiles() {
  //const [pads, setPads] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [screencaptures, setScreencaptures] = useState({});
  const [activeFilters, setActiveFilters] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { meta, setMeta } = useContext(AuthContext); //needed???

  const filters = useMemo(() => getFilters(files), [files.length]); // eslint-disable-line react-hooks/exhaustive-deps
  const filteredFiles = sortFiles(filter(files, activeFilters));

 /*  useEffect( ()=> {
    console.log("::::: FILTERED FILES :::::: ")
    console.log(filteredFiles)

    console.log("::::: projects :::::")
    const uniq = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
    //console.log(uniq(filteredFiles.map((file) => file.project || file[0].project).filter(Boolean)))
  
  }, [filteredFiles] ) */

  function addPad() {
    let c = window.confirm("Do you want to add a new pad?");
    if (c) {
      let newKey = base.ref(paths.PAD_STORAGE).push().getKey();
      base.ref(paths.PAD_METADATA).child(newKey).update({ref: newKey});

      alert(`Ok! Refresh the Live Archive to view.`);
    }
  }
    
  async function fetchFiles(filesToAdd=[]) {
    const files = await getFiles();

    // begin firebase
    const snapshot = await base.ref(paths.PAD_METADATA).get()
    
    let pad_meta = []
    snapshot.forEach(pad=>{pad_meta.push({
      Key: pad.key,
      ref: pad.key,
      doctype: 'pad',
      date: pad.val().date || '?',
      project: pad.val().project|| '?',
      location: pad.val().location|| '?',
      author: pad.val().author || '?',
    })})
    //console.log(pads);
    let pad_meta_obj = {}
    pad_meta.map(pad=>{
      pad_meta_obj[pad.Key] = {
        ref: pad.ref,
        doctype: 'pad',
        date: pad.date,
        project: pad.project,
        location: pad.location,
        author: pad.author,
      }
    })
    setMeta(pad_meta_obj);
    //end firebase init

    setFiles(
      sortFiles(
        transform(
          [...files.data.filter((file) => !file.Key.startsWith("6. current")), ...pad_meta]
        )
      )
    );


    /* firebase.database().ref(paths.PAD_METADATA).get().then( snapshot=> {
      let pad_meta = []
      snapshot.forEach(pad=>{pad_meta.push({
        Key: pad.key,
        ref: pad.key,
        doctype: 'pad',
        date: pad.val().date || '?',
        project: pad.val().project|| '?',
        location: pad.val().location|| '?',
        author: pad.val().author || '?',
      })})
      //console.log(pads);
      let pad_meta_obj = {}
      pad_meta.map(pad=>{
        pad_meta_obj[pad.Key] = {
          ref: pad.ref,
          doctype: 'pad',
          date: pad.date,
          project: pad.project,
          location: pad.location,
          author: pad.author,
        }
      })
      setMeta(pad_meta_obj);

      setFiles(
        sortFiles(
          transform(
            [...files.data.filter((file) => !file.Key.startsWith("6. current")), ...pad_meta]
          )
        )
      );
    }) */

    setIsLoading(false);
    return;
  }

  function onFilter(key, value) {
    if (!key && !value) {
      return setActiveFilters({});
    }

    const key_filters = activeFilters[key] || [];
    const filters = {
      ...activeFilters,
      [key]: includes(key_filters, value)
        ? key_filters.filter((item) => item !== value)
        : [...key_filters, value],
    }

    return setActiveFilters(filters);
  }

  function onSelectFile(file) {
    return setSelectedFiles(
      isSelected(selectedFiles, file)
        ? selectedFiles.filter((selectedFile) => selectedFile.id !== file.id)
        : [...selectedFiles, file]
    );
  }

  return {
    activeFilters,
    fetchFiles,
    files,
    setFiles,
    filteredFiles,
    filters,
    isLoading,
    onFilter,
    onSelectFile,
    screencaptures,
    selectedFiles,
    setScreencaptures,
    setSelectedFiles,
    addPad,
  };
}

import React from "react";
import { FACEBOOK } from "../constants";

import styles from "./Home.module.css";

const Information = ({ onClose }) => (
  <div className={styles.Information}>
    <button className={styles.close} onClick={onClose}>
      ✕
    </button>
    <div className={styles.body}>
      <h3>How to use the Live Archive</h3>
      <p>
        The Live Archive is both a working tool and a library of images, texts
        and videos related to our artistic work and research.{" "}
        <u>
          <em>Filter</em>
        </u>{" "}
        through the material based on your personal interest.{" "}
        <u>
          <em>Click</em>
        </u>{" "}
        on ‘selection’, to save and print a personal catalogue of our work.
      </p>
      <h3>About TAAT</h3>
      <p>
        TAAT operates as a performative spatial practice in between the fields
        of architecture, performance and artistic research. The core of our work
        is the project{" "}
        <em>
          HALL33, a building that is a theatre play, and a theatre play that is
          a building
        </em>
        . HALL33 facilitates encounters between audience members, institutions,
        practitioners, researchers and students and has been unfolding as
        ‘rehearsals through space’ from 2012 until now. All projects initiated
        by TAAT are framed within an <em>open source methodology</em>
        that is based on <em>collective learning-by-doing</em> approach.
      </p>
      <p>
        <em>
          “TAAT’s work is situated in the interdisciplinary terrain of socially
          and spatially engaged artistic work that takes place in, and explores,
          urban public space and its social conditions. Operating ‘outside’ or
          ‘in-between’ the established fields of art, theatre and architecture,
          TAAT’s work is a form of critical spatial practice: they use the
          performativity of space to mediate intersubjective interaction,
          questioning disciplinary procedures and working through a collective
          and distributed understanding of authorship. Common to socially
          engaged art and performance practices their work provides
          opportunities for embodied social interaction and exchange.” (Andrew
          Filmer, ConferencePaper IFTR 2019)
        </em>
      </p>
      <p>
        TAAT is registered as a foundation (stichting) with a Non-Profit Status
        (since November '21) and is based in Maastricht (NL). You can find our
        strategic vision, our goals and activities, our board, our sustainability
        model and our financial reporting <a href="/protocol">here</a>.
      </p>

      <h3>Agenda</h3>
      <p>
        You can find more information on our current and upcoming events{" "}
        <a href={FACEBOOK} target="_blank" rel="noopener noreferrer">here</a>.
      </p>

      <h3>Collaborations</h3>
      <p>
        TAAT was founded in 2012 by{" "}
        <a href="mailto:gert-jan@taat-projects.com">Gert-Jan Stam</a> and{" "}
        <a href="mailto:breg@taat-projects.com">Breg Horemans</a> after a
        collaboration on the project <u>KHORI</u> (Venlo, Floriade 2012)
        commissioned by Huis van Bourgondië, Maastricht NL.{" "}
        <u>Ilona van den Brekel</u> joined in 2016 as a general coördinator.
        TAAT focusses on individual connections within a larger frame of
        universities and multidisciplinary art institutions. We are currently
        developing HALL08-12 in collaboration with Efrosyni Tsiritaki (Athens,
        GR) Menzo Kircz (Amsterdam, NL), Jenny Case (Dundee, UK), Liga Zepa
        (Riga, LV), Goda Verikaite (Rotterdam, NL).
      </p>
      <p>
        The development of <u>HALL33</u> is based on a network of collaborations
        with KASK (BE), KISD (DE), Aberystwyth University (WA), Alanus
        University, DE, KABK (NL), Theatre Academy Riga (LV) and
        supported/presented by C-TAKT, (partners) Dutch Creative Industries
        Fund, Art&Amen Köln, BUDA Kortrijk, Biënnale Interieur Kortrijk, DAZ
        Berlin, CAMPO Ghent, Design Museum Ghent, Gessnerallee Zürich, SIA
        Zürich, Verbeke Foundation, Homo Novus Festival (Riga, LV) and Sansusi
        Festival (Akniste, LV).
      </p>
      <p>
        <u>BOKRIJK SENGU</u> was created in collaboration with Jolien Naeyaert,
        Ward Delbeke, Bas Vrehen, Frederik Schobben en Sylvie Hagens and
        commissioned by Open Air Museum of Bokrijk, Genk, BE.
      </p>

      <p>
        <em>
          TAAT is structurally supported by SoAP foundation (Maastricht, NL) and
          the Dutch Performing Arts Fund.
        </em>
      </p>
    </div>
  </div>
);

Information.propTypes = {};

export default Information;

import React, { useState, useRef } from "react";
import Slider from "react-slick";
import cx from "classnames";

import { Image, Video } from ".";
import styles from "./Carousel.module.css";
import { useEffect } from "react";

const Carousel = (props) => {
  const {
    autoPlay,
    initialSlide,
    isFullScreen,
    isMuted,
    onClose,
    slides = [],
    files = [],
  } = props;
  const [activeSlide, setActiveSlide] = useState(initialSlide);
  const slider = useRef();

  // console.log("CAROUSEL SLIDES::::");
  // console.log(slides);

  const settings = {
    arrows: isFullScreen,
    autoplay: autoPlay,
    beforeChange: isFullScreen ? undefined : (_, next) => setActiveSlide(next),
    lazyLoad: autoPlay,
    focusOnSelect: true,
    autoplaySpeed: 6000,
    dots: false,
    fade: true,
    infinite: true,
    initialSlide,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: isFullScreen ? 200 : 1000,
    swipeToSlide: !isFullScreen,
    accessibility: true,
  };

  useEffect(() => {
    console.log("ACTIVE SLIDE:::::");
    console.log(activeSlide);
  }, [activeSlide])

  useEffect(() => {
    if (slider) {
      slider.current.innerSlider.list.setAttribute("tabindex", 0);
      slider.current.innerSlider.list.focus();
    }
  }, [slider]);

  return (
    <div className={cx(styles.wrapper, { [styles.fullScreen]: isFullScreen })}>
      {isFullScreen && (
        <button className={styles.close} style={{color: slides && slides[activeSlide]?.props?.file?.doctype==='pad' ? '#000' : '#fff' }} onClick={onClose}>
          ✕
        </button>
      )}
      <Slider {...settings} ref={slider}>
        {/* Archive enlarge */}
        {slides.map((slide, idx) => (
          <>
          <div className={styles.slide} key={`slide_${idx}`}>
            <div className="pad"><p>{idx}</p></div>
            {slide}
          </div>
          </>
        ))}
        {/* Home carousel */}
        {files.map((file, idx) => (
          <div className={styles.slide} key={`slide_${idx}`}>
            {file.Key.endsWith(".mp4") ? (
              <Video
                src={file.url}
                autoPlay
                isActive={activeSlide === idx}
                isMuted={isMuted}
              />
            ) : (
              <Image url={file.url} />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default Carousel;

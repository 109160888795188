
import { FIREBASE_CONFIG } from "../constants";


export const app = window.firebase.initializeApp(FIREBASE_CONFIG);
export const base = app.database();
export const auth = app.auth();

export const paths =  {
                        PAD_METADATA: 'pads/metadata',
                        PAD_STORAGE: 'pads/storage',
                        EDIT_METADATA: 'livearchive/edits/metadata',
                        EDIT_STORAGE: 'livearchive/edits/annotations',
                      } 
import React from "react";
import { format } from "date-fns";
import { DATE_FORMAT_YYMMDD } from "../constants";

import styles from "./Preview.module.css";

export const PageFooter = ({ page, pageTotal }) => {
  const today = format(new Date(), DATE_FORMAT_YYMMDD);

  return (
    <div className={styles.footer}>
      <div>edit {today}{/*editor && `_${editor}`*/}{/*selected on {today}*/}</div>
      <div>{page} / {pageTotal}</div>
      <div>www.taat-projects.com</div>
    </div>
  );
}

export default PageFooter;

import React, { useRef, useState, useContext, useEffect} from "react";
import cx from "classnames";

import useOnOutsideClick from "../../utils/useOnOutsideClick";
import includes from "../../utils/includes";
import { FILTER_TRANSLATIONS } from "../../constants";

import styles from "./EditsDropdown.module.css";

import { AuthContext } from "../../Firebase/context";

const BODY_CLASS = "dropdown-open";

const EditsDropdown = ({ numFilesSelected, onSelect, setSelectedFiles }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { editsMetadata, activeEdit, activeEditId, setActiveEditId } = useContext(AuthContext);


  function handleClick(event) {
    if (activeEditId) {
      handleSelect(activeEditId)
    } else {
      if (numFilesSelected > 0)
        handleSelect(null) //goto 'current' selection
      else
        toggle(event) //toggle dropdown menu
    }
  }

  function toggle(event) {
    event && event.preventDefault();

    if (!isOpen) {
      document.body.classList.add(BODY_CLASS);
      return setIsOpen(true);
    }

    close();
  }

  function close() {
    document.body.classList.remove(BODY_CLASS);
    return setIsOpen(false);
  }

  useOnOutsideClick(ref, (event) => {
    if (isOpen) {
      toggle(event);
    }
  });

  const handleSelect = (key) => {
    if (activeEditId && (key !== activeEditId))
      setSelectedFiles([]);

    if (!activeEditId && key !== null && numFilesSelected > 0) {  
      if (window.confirm(`There ${numFilesSelected>1 ? 'are' : 'is'} currently ${numFilesSelected} document${numFilesSelected>1 ? 's' : ''} selected. This selection will be replaced by those in the selected selection. To proceed click OK. To go back click CANCEL.`))
        setSelectedFiles([]);
      else {
        if (isOpen) close()
        return
      }
    }

    if (!activeEditId && key == null && numFilesSelected == 0) {
      window.alert(`To enter the current selection zone, first select at least one document by clicking on its title.`)
      if (isOpen) close()
      return
    }

    setActiveEditId(key);
    onSelect();
    
    if (isOpen) close()
  }

  //const items = [`current (${numFilesSelected})`, ...listOfEdits]
  const items = [
    {
      name: `current (${numFilesSelected})`,
      key: null,
    },
  ].concat(editsMetadata)

  return (
    <div className={styles.Dropdown} ref={ref}>
      <div className={styles.Button} onClick={handleClick}>
        {!activeEditId ? numFilesSelected > 0 ? items[0].name : `select/edit` : <span style={{background: 'yellow'}}>{activeEdit.name}</span> }
      </div>
      {isOpen && (
        <ul>
          {items.map( ({name, key}, index) => (index > 0 || !activeEditId) && (
            <li
              key={key}
              className={cx({
                [styles.selected]: key===activeEditId /* && i!==0 */
              })}
              onClick={()=>handleSelect(key)}
            >
              {name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

EditsDropdown.propTypes = {};

export default EditsDropdown;

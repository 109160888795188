
import React from "react";
import LazyLoad from "react-lazyload";

import Pad from "./Pad";

const LazyPad = (props) => (
  <LazyLoad offset={ 400 } height={400} >
    <Pad {...props} />
  </LazyLoad>
);

export default LazyPad


import React from "react";
import LazyLoad from "react-lazyload";

import Video from "./Video";
//const Video = React.lazy(()=>import('./Video'))

const LazyVideo = (props) => (
  <LazyLoad offset={600} height={400}>
    <Video {...props} />
  </LazyLoad>
);

export default LazyVideo

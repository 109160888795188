import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import FullScreenButton from "../FullScreenButton";

const Image = ({ alt, onOpen, src, scrollPosition }) => (
  <>
    {onOpen && <FullScreenButton onClick={onOpen} />}
    <LazyLoadImage alt={alt} src={src} /* effect="opacity" */ scrollPosition={scrollPosition} />
  </>
);

export default Image;

import React from "react";
import cx from "classnames";
import Menu from "../Menu";

import styles from "./Filters.module.css";

const MobileFilters = (props) => {
  const { activeFilters, filters = {}, onFilter } = props;

  return (
    <div className={cx(styles.Filters, styles.mobile)}>
      <Menu
        items={filters}
        selectedItems={activeFilters}
        onSelect={onFilter}
      />
    </div>
  );
};

export default MobileFilters;

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Redirect, useHistory } from "react-router-dom";

import Home from "./Home/Home";
import Archive from "./Archive";
import Login from "./Login";

import ReadOnlyPad from "./ReadOnlyPad"
import { BaseContextProvider } from "./Firebase/context";
import { auth } from "./Firebase/config";


function Logout() {
  auth.signOut().then(()=>{
    console.log("you signed out")
  });
  return <Redirect to={{ pathname: "/" }} />
}


function Nav () {
  const history = useHistory();
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/protocol">
        <ReadOnlyPad id="-MjiLlg3bkCpnQvBoUSt" onClose={()=>history.push('/')}/>
      </Route>
      <Route path={`/pad/:id`}>
        <ReadOnlyPad />
      </Route>
      <Route path="/archive">
        <Archive />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  )
}

function App () {
  return (
    <BaseContextProvider>
      <Router>
        <Nav />
      </Router>
    </BaseContextProvider>
  );
};

export default App;
